.primary {
  color: $primary !important;
}

.bg-primary {
  color: white !important;
  background-color: $primary !important;
}

.border-primary {
  border-color: $primary !important;
}

.success {
  color: $success !important;
}

.bg-success {
  color: $grey-dark !important;
  background-color: $success !important;
}

.border-success {
  border-color: $success !important;
}

.black {
  color: $black !important;
}

.bg-black {
  color: white !important;
  background-color: $black !important;
}

.border-black {
  border-color: $black !important;
}

.white {
  color: white !important;
}

.bg-white {
  background-color: white !important;
}

.grey {
  color: $grey !important;
}

.grey-light {
  color: $grey-light !important;
}

.bg-grey {
  color: white !important;
  background-color: $grey !important;
}

.border-grey {
  border-color: $grey !important;
}

.purple {
  color: $purple !important;
}

.bg-purple {
  color: white !important;
  background-color: $purple !important;
}

.border-purple {
  border-color: $purple !important;
}

.warn {
  color: $warn !important;
}

.bg-warn {
  color: $grey-dark !important;
  background-color: $warn !important;
}

.border-warn {
  border-color: $warn !important;
}

.error {
  color: $error !important;
}

.bg-error {
  color: white !important;
  background-color: $error !important;
}

.border-error {
  border-color: $error !important;
}

.bg-light {
  background-color: transparent;
  background-image: $light;
}

.bg-dark {
  background-color: transparent;
  background-image: $dark;
}

.gradient-light {
  background-image: $gradient-light;
}

.gradient-dark {
  background-image: $gradient-dark;
}

.gradient-theme {
  background-image: $gradient-theme;
}

.flat {
  box-shadow: none !important;
}

.flat:hover {
  background-color: $primary-light;
  opacity: 1;
}

.bg-primary,
.bg-error,
.bg-warn,
.bg-success,
.bg-black,
.bg-purple {
  &:disabled {
    background-color: $grey !important;
    color: $white !important;
  }
}
