.onboarding {
  @extend .row;
  background-color: $primary;
  padding: 5% 10%;
  height: 100%;
  width: 100%;
  background-image: $gradient-dark;
  justify-content: center;

  @include respond-to(phone) {
    padding: 0;
  }

  @at-root #{&}__wrapper {
    @extend .column;
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    position: relative;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    @include respond-to(phone) {
      height: 100%;
      width: 100%;
      box-shadow: none;
    }
  }

  @at-root #{&}__header {
    @extend .row;
    padding: 3%;
    justify-content: space-between;
    width: 100%;
    flex-shrink: 0;

    .logo img {
      width: 20rem;

      @include respond-to(phone) {
        width: 12rem;
      }
    }
  }

  @at-root #{&}__content {
    @extend .column;
    width: 100%;
    padding-bottom: 10%;
    flex-grow: 1;
    justify-content: center;
    align-items: center;

    h1 {
      color: $primary;
      font-size: 2rem;
      font-weight: normal;
      margin: 0.5rem 0;
    }

    p {
      @extend .row;
      font-size: 1.2rem;
      font-weight: lighter;
      margin: 0;
      padding: 0.5rem;
      line-height: 1.5rem;

      a {
        font-size: 1.2rem;
        font-weight: normal;
      }

      &:first-of-type {
        margin-top: 2.5rem;
      }
    }
  }
}
