.auth {
  background-color: $primary;
  background-image: $gradient-dark;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include respond-to(phone) {
    background-color: white;
    // background-image: $gradient-theme;
    background-image: none;
  }

  @at-root #{&}__wrapper {
    display: flex;
    flex-direction: row;
    margin: auto;
    width: 100%;
    height: 100%;
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    @include respond-to(phone) {
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      flex-direction: column;
      background-color: transparent;
      box-shadow: none;
      justify-content: center;
    }
  }

  @at-root #{&}__feature {
    flex-grow: 1;
    padding: 2rem;
    height: 100%;
    display: flex;
    flex-direction: column;

    @include respond-to(phone) {
      flex-grow: 0;
      height: auto;
      padding: 1rem 4rem;
    }

    .logo {
      justify-self: flex-start;

      img {
        width: 20rem;

        @include respond-to(phone) {
          width: 60%;
        }
      }
    }

    .feature-img {
      justify-content: center;
      align-items: center;
      flex-grow: 1;

      @include respond-to(phone) {
        display: none;
      }

      img {
        width: 65%;
        max-width: 60rem;
      }
    }
  }

  @at-root #{&}__form {
    display: flex;
    flex-direction: column;
    min-width: 30rem;
    width: 30%;
    max-width: 50rem;
    height: 100%;
    flex-shrink: 0;
    background-color: $primary-light;
    padding: 2rem 4rem;
    color: $grey;
    justify-content: center;

    @include respond-to(phone) {
      width: 100%;
      height: auto;
      padding: 0 4rem;
      padding-bottom: 2rem;
      justify-content: flex-start;
      // background-color: white;
      background-color: transparent;
    }

    .language {
      text-transform: uppercase;
      align-self: flex-end;
      color: $grey;
      padding: 0;
      margin: 0;
      margin-bottom: 2rem;

      @include respond-to(phone) {
        margin-bottom: 0;
      }
    }

    label {
      text-transform: capitalize;
      margin-bottom: 1.5rem;
      color: $grey;
    }

    .instruction {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 0.9rem;
      align-items: center;
      padding: 0;
      padding-top: 1.5rem;

      .row {
        margin-top: 0.5rem;
      }

      a {
        text-decoration: none;
        text-transform: capitalize;
        color: $primary;
        font-size: 0.9rem;
        margin-left: 0.5rem;
        font-weight: bold;
        padding: 0;
      }
    }

    p {
      display: flex;
      flex-direction: row;
      justify-content: center;
      font-size: 1rem;
      align-items: center;
      padding: 1rem 0;
    }

    .oauth {
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      width: 100%;

      button {
        width: 48%;
        border-radius: 2px;
        font-size: 1.1rem;
        background-color: $white;

        @include respond-to(phone) {
          box-shadow: $box-shadow;
        }

        img {
          width: 1.5rem;
          margin-right: 0.5rem;
        }
      }
    }

    .text-button {
      padding: 1.2rem 0;
      margin-left: 0;
      border-radius: 2px;

      @include respond-to(phone) {
        box-shadow: $box-shadow;
      }
    }

    @at-root #{&}__row {
      display: flex;
      flex-direction: column;
      background-color: $white;
      margin-bottom: 1.5rem;
      width: 100%;
      justify-content: center;
      padding: 1.2rem;
      border-radius: 2px;
      box-shadow: $box-shadow;
      border-left: 0.5rem solid $white;
      align-items: flex-start;

      @include respond-to(phone) {
        box-shadow: $box-shadow;
      }

      label {
        font-weight: lighter;
        color: $grey;
        font-size: 0.9rem;
        text-transform: capitalize;
        margin-bottom: 0.5rem;
      }

      input {
        border: none;
        border-bottom: 1px solid $primary-light;
        outline: none;
        font-size: 1rem;
        color: $font-color;
        background-color: transparent;
        width: 100%;
        border-radius: 0;

        @include respond-to(phone) {
          font-size: 16px;
        }
      }

      &:focus-within {
        border-left: 0.5rem solid $primary;
      }
    }
  }
}
