.inbox {
	height: 100%;
	width: 100%;

	@at-root #{&}__chatlist {
		@extend .scrollable;
		@extend .column;
		width: 20%;
		height: 100%;
		padding: 0 1rem 1rem 0;
		flex-shrink: 0;
		border-right: 1.5px solid $grey-light;

		@include respond-to(phone) {
			width: 100%;
			border: none;
			padding: 1rem;
		}

		@include respond-to(tablet) {
			width: 30%;
		}

		&.hide-in-mobile {
			@include respond-to(phone) {
				display: none;
			}
		}

		.chat-item {
			background-color: $white;
			padding: 1rem;
			margin-bottom: 0.5rem;
			border-right: 6px solid $white;
			width: 100%;
			cursor: pointer;
			flex-shrink: 0;
			border-radius: $border-radius 2px 2px $border-radius;
			box-shadow: $box-shadow;

			@include respond-to(phone) {
				border-right: none;
				border-radius: $border-radius;
			}

			> * {
				user-select: none;
			}

			.user-icon {
				@include respond-to(phone) {
					width: 4rem;
					height: 4rem;
				}
			}

			.chat-info {
				padding: 0.2rem 0.5rem;
				justify-content: space-evenly;
				flex-grow: 1;
				min-width: 0;

				.customer-info {
					@extend .ellipsis;
					font-size: 1rem;

					@include respond-to(phone) {
						font-size: 1.3rem;
					}
				}

				.chat-preview {
					@extend .ellipsis;
					margin-top: 0.2rem;
					font-size: 0.9rem;
					font-weight: lighter;
					color: $grey;

					// @include respond-to(phone) {
					//   font-size: 1.1rem;
					// }
				}
			}

			.chat-status {
				flex-shrink: 0;
				height: 100%;
				align-items: center;
				position: relative;

				img {
					width: 1.4rem;
				}

				span {
					background-color: $warn;
					background-image: none;
					width: 6px;
					height: 6px;
					border-radius: 50%;
					margin-right: 1rem;
				}
			}
		}

		.chat-item.active {
			border-right: 6px solid $primary;

			@include respond-to(phone) {
				border-right: none;
			}
		}
	}

	@at-root #{&}__chatroom {
		@extend .column;
		margin-left: 1rem;
		flex-grow: 1;
		height: 100%;
		border-radius: $border-radius;

		.chatroom__info {
			padding: 1rem 2rem;
			flex-shrink: 0;
			background-color: $white;
			border-bottom: 1px solid $primary-light;
			height: 7rem;
			align-items: center;
			z-index: 1;
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			border-radius: $border-radius;

			@include respond-to(phone) {
				padding: 1.5rem 1rem;
				height: auto;
				background-color: $primary;
				background-image: $gradient-dark;
				color: white;
				box-shadow: $box-shadow-dark;
				border: none;
				border-radius: 0;
			}

			@include respond-to(tablet) {
				padding: 0.8rem 1rem;
				height: auto;
			}

			.icon-button {
				font-size: 1.5rem;
				color: $primary;

				@include respond-to(phone) {
					color: white;
				}
			}

			#btn-back {
				margin-right: 0.5rem;
				display: none;

				@include respond-to(phone) {
					display: flex;
				}
			}

			.user-icon {
				width: 4rem;
				height: 4rem;

				&.transparent {
					background-color: transparent;
				}

				@include respond-to(phone) {
					width: 5rem;
					height: 5rem;
				}
			}

			@at-root #{&}__customer {
				padding: 1rem;
				justify-content: center;
				flex-grow: 1;
				min-width: 0;
				display: flex;
				flex-direction: column;

				@at-root #{&}__tag {
					margin-left: 1rem;

					button {
						font-size: 1rem;
					}
				}

				@at-root #{&}__name {
					@extend .ellipsis;
					font-size: 1.3rem;
					font-weight: normal;
					align-items: center;
				}

				@at-root #{&}__contact {
					@extend .ellipsis;
					margin-top: 0.5rem;
					font-size: 1rem;
					color: $grey;
				}
			}

			@at-root #{&}__action {
				flex-shrink: 0;
				display: flex;
				flex-direction: row;
				align-items: center;

				button {
					@extend .flat;
				}
			}
		}

		app-chatroom {
			flex-grow: 1;
			position: relative;
		}

		@include respond-to(phone) {
			width: 100%;
			margin: 0;
		}
	}
}
