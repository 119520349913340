.setting {
	@extend .scrollable;
	background-color: white;
	padding: 4rem 8rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: $border-radius;

	@include respond-to(phone) {
		padding: 1rem;
		padding-bottom: 3rem;
		height: 100%;
		border-radius: 0;
	}

	@at-root #{&}__user {
		display: flex;
		flex-direction: row;
		margin: 2rem 0 1rem 4rem;
		width: 100%;
		flex-shrink: 0;

		@include respond-to(phone) {
			padding: 1rem;
			margin: 0;
			justify-content: space-between;
		}

		@at-root #{&}__photo {
			position: relative;
			display: flex;
			flex-direction: row;

			.user-icon {
				width: 6rem;
				height: 6rem;
				margin-right: 2rem;
			}

			.icon-button {
				position: absolute;
				top: 0;
				left: 0;
				width: 6rem;
				height: 6rem;
				padding: 0;
				background-color: $grey;
				opacity: 0.6;
				border-radius: 50%;
				margin: 0;
			}
		}

		@at-root #{&}__info {
			display: flex;
			flex-direction: column;
			justify-content: center;
			font-size: 1.2rem;

			.icon-button {
				font-size: 1.5rem;
			}

			input {
				font-size: 1.2rem;
				font-weight: normal;
				margin-right: 2rem;

				@include respond-to(phone) {
					font-size: 1.3rem;
					margin-right: 0;
				}
			}

			@at-root #{&}__name {
				display: flex;
				flex-direction: row;
				align-items: center;
				min-height: 3rem;
			}

			@at-root #{&}__email {
				font-size: 0.9em;
				color: $grey;
			}
		}
	}
}

.channel-header {
	@extend .column;
	border-bottom: 1px solid $primary-light;
	padding-bottom: 2rem;

	.form__row {
		padding: 2rem;
		border-bottom: none;
		padding-bottom: 0;
		padding-right: 0;
	}

	.form__action {
		justify-content: flex-end;
		@include respond-to(phone) {
			width: 100%;
		}
	}
}

.channel-action {
	@extend .row;
	justify-content: flex-end;
	padding-top: 2rem;
}

.channel-image {
	flex-shrink: 0;
	padding-top: 1rem;
	padding-right: 2rem;
	align-self: flex-start;

	@include respond-to(phone) {
		display: none;
	}

	img {
		width: 8rem;
	}
}

.channel-description {
	@extend .column;
	align-items: flex-start;
	flex-grow: 1;

	h1 {
		text-transform: uppercase;
		font-size: 1.5rem;
		font-weight: bold;
		margin: 0.5rem 0;
		margin-left: 1.5rem;
		color: $primary;
	}

	img {
		width: auto;
		height: 4rem;
	}

	ul {
		align-self: flex-start;
		margin-left: -1rem;
	}

	li {
		padding: 0.5rem 0;

		@include respond-to(phone) {
			padding: 0;
		}

		.material-icons {
			font-size: 1rem;
		}
	}
}

.channel-details-title {
	@extend .row;
	margin: 3rem 0;
	margin-bottom: 1rem;

	@include respond-to(phone) {
		flex-wrap: wrap;
		margin: 1rem 0;
	}

	h2 {
		text-transform: uppercase;
		font-size: 1.2rem;
		flex-grow: 1;
		color: $primary;
	}

	.action {
		@extend .row;
		justify-content: flex-end;
		flex-shrink: 0;
		width: 20%;
		padding-left: 2rem;

		@include respond-to(phone) {
			width: 100%;
		}
	}
}

.channel-details {
	padding: 2rem;
	@include respond-to(phone) {
		padding: 0 1rem;
	}
}

.channel-item {
	@extend .row;
	padding: 2rem 0;
	border-bottom: 1px solid $primary-light;

	.info {
		flex-grow: 1;

		.form__row {
			padding: 1rem 0;
			border-bottom: none;
		}

		.form__field {
			width: auto;
			flex-grow: 1;
		}
	}

	.picture {
		@extend .row;
		flex-shrink: 0;
		width: 250px;
		justify-content: center;

		@include respond-to(phone) {
			display: none;
		}

		img {
			width: 200px;
			height: 200px;
			border-radius: $border-radius;
		}
	}

	.action {
		@extend .column;
		padding: 1rem 0;
		align-self: flex-end;
		flex-shrink: 0;
		width: 5%;
		align-items: flex-end;

		.icon-button {
			font-size: 1.5rem;
			margin: 0;
		}
	}
}
