button,
a {
  @extend .ellipsis;
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  cursor: pointer;
  outline: none;
  border: none;
  user-select: none;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1rem;
  text-transform: capitalize;
  padding: 0.5rem;
  border-radius: $border-radius;
  text-align: center;
  justify-content: center;
  background-color: transparent;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    opacity: 0.9;
  }

  span {
    margin-left: 0.6em;
    padding: 0 !important;
    @include respond-to(phone) {
      margin-left: 0.2em;
    }
  }

  span:first-child {
    padding-left: 0;
    margin-left: 0;
  }
}

.icon-button {
  border-radius: 50%;
  width: fit-content;

  &:disabled {
    color: $grey !important;
  }

  &:hover:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.5);
  }
}

button {
  margin-right: 0.5rem;
  min-width: 3rem;
  min-height: 3rem;
  line-height: 1;

  app-loader {
    z-index: 0;
    position: absolute;
    padding: 0 !important;
    margin: auto;
  }

  .material-icons {
    margin: 0;
    font-size: 1.2em;
  }

  img {
    margin: 0;
    width: 1em;
  }

  &:last-child {
    margin-right: 0;
  }
}

.border-button,
.text-button {
  box-shadow: $box-shadow;
  padding: 0.8rem 1.5rem;
}

.border-button {
  border: 1px solid;
  box-shadow: none;
  border-radius: $border-radius;
}

button.language {
  text-transform: uppercase;
}

.material-icons {
  user-select: none;
}

a.mat-menu-item,
button.mat-menu-item {
  box-shadow: none;
  border-radius: 0;
  justify-content: flex-start;
  display: flex;
  flex-direction: row;
  background-color: white;
  font-size: 1rem;
  margin: 0;
  max-width: 20rem;

  .material-icons {
    font-size: 1.1em;
  }

  span {
    @extend .ellipsis;
  }

  img {
    margin-right: 0.5em;
  }

  &:hover:not([disabled]) {
    background-color: $primary-light;
  }

  &.selected,
  &.selected:hover:not([disabled]) {
    background-color: $primary;
    color: $white !important;
  }
}

.radio-button-group {
  @extend .row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.radio-button {
  input[type="radio"] {
    display: none;
    // position: absolute;
    // opacity: 0;
    // cursor: pointer;
    // height: 0;
    // width: 0;
  }

  label {
    color: $primary;
    font-weight: lighter;
    font-size: 1.5em;
    text-align: center;
    display: flex;
    flex-direction: row;
    height: 2.5em;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    border-radius: 5em;
    margin: 0 0.5em;
    margin-bottom: 0.5em;
    min-width: 10em;
    cursor: pointer;

    &.checked {
      border: 2px solid $primary;
      background-color: $primary;
      color: $white;
      box-shadow: $box-shadow;
      font-weight: bold;
    }
  }
}

.text-button {
  @include respond-to(phone) {
    padding: 0.5rem;
  }
}

.text-button span {
  @include respond-to(phone) {
    display: none;
  }

  &.keep,
  &:only-child {
    @include respond-to(phone) {
      display: block;
    }
  }
}

.language.text-button {
  padding: 1rem;
}
