.chatroom {
  flex-grow: 1;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  app-loader {
    z-index: 0;
    position: absolute;
  }

  .empty-placeholder {
    font-size: 1rem;

    .wrapper {
      width: 20rem;
      height: 20rem;
    }
  }

  @at-root #{&}__content {
    @extend .column;
    @extend .scrollable;
    flex-grow: 1;
    margin: 0;
    padding: 1rem 1rem 0 1rem;
    border-radius: 0;
    background-color: $white;
    margin-top: -2px;

    @include respond-to(phone) {
      padding: 1.5rem 1rem;
      height: auto;
    }
  }
}

.chatroom__input {
  @extend .row;
  padding: 1rem;
  flex-shrink: 0;
  border-top: 1px solid $primary-light;
  align-items: center;
  background-color: $white;
  border-radius: 0 0 $border-radius $border-radius;
  margin-top: -2px;
  z-index: 1;

  input {
    background-color: transparent;
    border: none;
    flex-grow: 1;
    font-size: 1.2rem;
  }

  .text-button {
    @extend .ellipsis;
    color: $primary;
    justify-content: flex-start;
    text-align: left;
    padding: 0.2rem;
    flex-shrink: 0;
  }

  .actions {
    @extend .row;
    min-width: 9rem;

    .icon-button {
      font-size: 1.6rem;
      color: $grey;
      margin-right: 0;

      @include respond-to(phone) {
        font-size: 1.8rem;
      }
    }
  }

  p {
    color: $grey;
    width: 100%;
    text-align: center;
    white-space: normal;
  }
}

.chatroom-header {
  flex-shrink: 0;
  padding: 3rem 2rem;
  font-size: 1.6rem;
  display: flex;
  box-shadow: $box-shadow;

  .icon-button {
    margin-right: 1rem;
  }

  .material-icons {
    font-size: 1.6rem;
  }
}

.message {
  @extend .column;
  width: 100%;
  padding: 0 0.5rem;

  &.right {
    align-items: flex-end;
    text-align: right;
  }

  // .user-icon {
  //   width: 3rem;
  //   height: 3rem;
  //   margin-left: 0.5rem;
  //   margin-right: 0;
  //   font-size: 1.5rem;
  //   align-self: flex-end;
  //   background-color: transparent;

  //   @include respond-to(phone) {
  //     display: none;
  //   }

  //   img {
  //     width: 2.5rem;
  //   }
  // }
  @at-root #{&}__date {
    @extend .row;
    width: 100%;
    justify-content: center;
    color: $grey;
    text-transform: uppercase;
    padding: 0.5rem;
  }

  @at-root #{&}__wrapper {
    @extend .column;
    max-width: 70%;
    text-align: left;
    font-size: 0.8rem;
    font-weight: lighter;
    padding: 0.2rem;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 0.6rem;

    @at-root .right #{&} {
      align-items: flex-end;
    }
  }

  @at-root #{&}__text {
    display: block;
    position: relative;
    color: $font-color;
    background-color: $primary-light;
    background-image: $gradient-light;
    padding: 0.8rem;
    border-radius: 1rem;
    font-size: 1.1rem;
    word-break: break-word;
    font-weight: normal;
    width: fit-content;
    white-space: pre-line;

    @at-root .right #{&} {
      background-color: var(--themeColor, $primary);
      color: $white;
    }

    @include respond-to(phone) {
      font-size: 1.2rem;
    }
  }

  @at-root #{&}__attachment {
    @extend .row;
    @extend .no-select;

    @at-root .right #{&} {
      justify-content: flex-end;
    }

    @at-root #{&}__item {
      @extend .row;
      border-radius: 5px;
      font-size: 1rem;

      @at-root .right #{&} {
        justify-content: flex-end;
      }

      a {
        color: var(--themeColor, $primary);
        padding: 0.5rem 0;
      }

      img {
        width: 100%;
        max-width: 60rem;
        background-color: transparent;
        border-radius: $border-radius;
        padding: 1rem 0;

        @include respond-to(phone) {
          max-width: 40vw;
        }
      }

      .material-icons {
        font-size: 4rem;
      }
    }
  }

  @at-root #{&}__timestamp {
    @extend .row;
    @extend .no-select;

    font-size: 0.8rem;
    justify-content: flex-end;
    position: absolute;
    bottom: 0.9em;
    right: 0.5em;
    background: transparent;
    opacity: 0.5;

    i.material-icons {
      font-size: 1.2em;
      margin-left: 0.5em;
      opacity: 0.5;
    }

    img {
      margin-left: 0.5em;
      width: 1em;
    }
  }

  @at-root #{&}__remark {
    font-size: 0.8rem;
    padding: 0.5rem 1rem;
    padding-bottom: 0;
    color: $grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.2rem;

    @at-root .right #{&} {
      align-items: flex-end;
    }
  }

  @at-root #{&}__action {
    padding: 0 0.8rem;
    display: flex;
    flex-direction: row;

    @at-root .right #{&} {
      align-items: flex-end;
      margin-left: 0;
      margin-right: 5rem;
    }

    .text-button {
      color: $grey;
      font-size: 0.8rem;
      font-style: italic;
      text-transform: capitalize;
      max-width: 40rem;
      font-weight: lighter;
      padding: 0.5rem;
      min-height: 0;

      &:hover {
        background-color: $primary-light;
      }

      &.btn-dismiss:hover {
        background-color: $error-light;
      }
    }
  }
}

.quick-replies {
  @extend .row;
  width: 100%;
  justify-content: flex-end;
  padding: 1rem 0.7rem;

  .quick-reply {
    @extend .row;
    @extend .ellipsis;
    max-width: 10rem;
    padding: 0.8rem;
    background-color: transparent;
    border: 1px solid $primary;
    border-radius: 5px;
    font-size: 1rem;
    text-transform: none;
    color: $primary;
    min-height: 0;
  }

  .quick-reply:hover {
    background-color: $primary-light;
  }
}

.chatroom-preview {
  @extend .column;
  flex-shrink: 0;
  width: 22%;
  min-width: 30rem;
  max-width: 35rem;
  margin-left: 1rem;
  height: 100%;
  position: relative;
  box-shadow: $box-shadow;
  background-color: $white;
  align-self: flex-start;

  @include respond-to(phone) {
    display: none;
  }

  @include respond-to(tablet) {
    width: 24rem;
  }

  .header {
    @extend .row;
    justify-content: space-between;
    background-color: $primary;
    box-shadow: $box-shadow;
    flex-shrink: 0;
    padding: 1rem;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    z-index: 1;

    label {
      color: $white;
      font-size: 1.2rem;
    }
  }

  .actions {
    @extend .row;
    justify-content: flex-end;

    .icon-button {
      font-size: 1.2rem;
      color: $white;
    }
  }

  app-chatroom {
    flex-grow: 1;
    position: relative;
  }

  .mat-tab-body-content app-chatroom {
    position: initial;
  }
}
