.knowledge {
	flex-grow: 1;
	height: 100%;
	position: relative;

	@at-root #{&}__container {
		@extend .scrollable;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(50rem, 1fr));
		grid-template-rows: repeat(auto-fill, minmax(20rem, 1fr));
		justify-items: center;
		justify-content: center;
		grid-gap: 1rem;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		height: 100%;

		@include respond-to(phone) {
			grid-template-columns: 1fr;
			padding: 1rem 0.5rem;
		}

		@include respond-to(tablet) {
			grid-template-columns: 1fr;
		}
	}

	@at-root #{&}__state {
		margin: 0 2rem 2rem 2rem;
		font-size: 1.3rem;
		line-height: 2rem;
		text-transform: uppercase;
		font-weight: normal;
		color: $primary;
	}
}

.content-wrapper {
	@extend .row;
	height: 100%;
	width: 100%;
}

.knowledge__item {
	display: flex;
	flex-direction: row;
	background-color: $white;
	box-shadow: $box-shadow;
	border-radius: $border-radius;
	padding: 1rem 1rem 1rem 2rem;
	border: 4px solid transparent;
	min-height: 100%;

	@include respond-to(phone) {
		padding: 0.5rem;
	}

	@at-root #{&}__wrapper {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	@at-root #{&}__content {
		width: 100%;
		padding: 1rem 0;

		label {
			text-transform: uppercase;
			font-size: 1.2rem;
			margin-right: 1rem;
			font-weight: lighter;
			color: $black;
			padding: 0.5rem;
		}

		input[type="text"],
		textarea {
			width: 100%;
			font-weight: lighter;
		}

		@at-root #{&}__question {
			@extend .row;
			align-items: flex-start;
			margin: 1rem 0;
		}

		@at-root #{&}__answer {
			@extend .row;
			align-items: flex-start;

			.knowledge__answer {
				width: 100%;

				textarea {
					width: auto;
				}

				@at-root #{&}__type {
					@extend .row;
					margin-bottom: 1rem;
				}

				@at-root #{&}__attachment {
					@at-root #{&}__status {
						text-transform: lowercase;
						color: $primary;
					}
				}
			}
		}
	}

	@at-root #{&}__setting {
		@extend .column;
		width: 4rem;
		flex-shrink: 0;
		align-items: center;
		padding-top: 0.5rem;
		color: $primary;
		position: relative;
		margin-left: 1rem;

		mat-slide-toggle {
			margin-bottom: 1rem;
		}

		.icon-button {
			font-size: 1.5rem;
			color: $grey;
			margin-right: 0;
		}

		app-loader-button,
		app-status {
			position: absolute;
			bottom: 0;
		}

		app-status {
			width: 3rem;
			height: 3rem;
		}
	}
}

.knowledge__item.inactive {
	box-shadow: none;
}

.knowledge__item.disabled {
	box-shadow: none;
	opacity: 0.3;
}

.dialog .knowledge__item {
	box-shadow: none;
	padding: 0 1rem;
}
