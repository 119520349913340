.chatform {
  @extend .column;
  @extend .scrollable;
  padding: 1rem 2rem;
  padding-top: 0;
  background-color: $white;
  border-radius: $border-radius;
  height: 100%;
}

.chatform-editor {
  @extend .row;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  background-color: $primary-light;

  .editor {
    flex-grow: 1;
    height: 100%;

    @include respond-to(phone) {
      width: 100%;
    }
  }

  .drag-handle {
    top: 1.9rem;
  }

  .chatroom-preview {
    .mat-tab-labels {
      height: 3rem;
    }

    .mat-tab-group,
    .mat-tab-body-wrapper {
      height: 100%;
    }
  }
}

.chatform-editor__item {
  @extend .column;
  background-color: $white;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: 1.5em;
  margin-bottom: 1em;
  border-left: 3px solid transparent;
  font-size: 1rem;

  @include respond-to(phone) {
    margin: 1em 0.5em;
  }

  &.mat-expansion-panel {
    background-color: $white !important;
    margin-bottom: 1rem !important;
    border-radius: $border-radius;
    padding: 0;
    box-shadow: $box-shadow !important;

    @include respond-to(phone) {
      margin: 1em 0.5em;
    }
  }

  .mat-expansion-panel-header {
    @extend .row;
    height: auto !important;
    font-size: 1.2em;
    color: $grey-dark;
    font-weight: bold;
    align-items: center;
    padding-left: 2.5em;

    @include respond-to(phone) {
      padding-left: 1.5em;
    }

    label {
      @extend .row;
      margin-right: 1em;
      width: 2em;
      flex-shrink: 0;

      @include respond-to(phone) {
        width: 1.5em;
      }
    }

    .text-button {
      width: 12em;
      justify-content: flex-start;
      margin: 1em 0;
      padding: 0.8em;
      flex-shrink: 0;

      @include respond-to(phone) {
        width: auto;
      }
    }

    .question-preview {
      @extend .row;
      @extend .ellipsis;
      flex-grow: 1;
      font-size: 0.9em;
      margin: 0 2em;
      cursor: pointer;
      font-weight: normal;
    }
  }

  &.invalid {
    border-color: $error;
  }

  .mat-tab-labels {
    margin-top: 1em;
  }

  .mat-tab-labels:first-child {
    margin-top: 0;
  }

  .form__row {
    padding: 1em;
  }

  .form__label {
    width: 25%;
    align-self: flex-start;
  }

  textarea {
    min-height: 3rem !important;
    box-sizing: border-box;
  }

  .general {
    .form__row:first-child {
      border-top: 1px solid $grey-light;
    }
  }

  .operators {
    @extend .row;
    flex-wrap: wrap;

    button {
      font-size: 1.2em;
      font-weight: bold;
      line-height: 1;
      margin-bottom: 0.5em;
    }

    .operator {
      padding: 0;
      background-color: $black;
      color: $white;
      font-size: 1.5em;
    }
  }

  .formula {
    @extend .row;

    label {
      color: $grey;
      width: auto;
      font-size: 1.5em;
      margin-right: 1em;
    }
  }

  .column {
    .type {
      margin-bottom: 1em;
    }
  }

  .parameter {
    @extend .row;

    .key {
      width: 20%;
    }

    .value {
      width: 40%;
      margin-right: 0.5em;
      @include respond-to(phone) {
        width: 50%;
      }
    }

    .icon-button {
      flex-shrink: 0;
    }
  }

  @at-root #{&}__actions {
    @extend .row;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 0.5em 0;

    .icon-button {
      font-size: 1.5em;
      color: $primary;
    }

    .mat-checkbox {
      text-transform: uppercase;
      margin-right: 1em;
      font-size: 1em;
    }
  }

  @at-root #{&}__title {
    @extend .row;
    font-size: 1.2em;
    color: $primary;
    padding: 0 0 1em 0;
    text-transform: uppercase;
    font-weight: bold;

    .icon-button {
      font-size: 1.5em;
    }

    label {
      @extend .row;
      margin-right: 1em;
      min-width: 2em;

      .material-icons {
        font-size: 1.2em;
        margin-right: 0.2em;
      }
    }
  }

  @at-root #{&}__locales {
    @extend .column;
    padding: 0;

    .form__row {
      padding: 1em 0;
    }
  }

  .toolbar {
    @extend .row;
    padding-top: 1em;
  }

  @at-root #{&}__options {
    @extend .column;
    width: 100%;
  }
}

.chatform-editor__item__option {
  @extend .row;
  margin-bottom: 0.5em;

  input.value {
    width: 30%;
    @include respond-to(phone) {
      width: 50%;
    }
  }

  .material-icons {
    font-size: 1.5em;
  }

  .drag-handle {
    position: relative !important;
    width: auto !important;
    margin-right: 0.5em;
    left: 0;
    top: 0;
  }

  .text {
    @extend .row;
    width: 100%;

    // input {
    //   width: 50%;
    // }

    .label {
      color: $primary-light;
      margin-right: 0.5em;
      margin-left: 1em;
    }
  }
}

.poll {
  @extend .row;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1rem;

  @at-root #{&}__question {
    @extend .column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 1em;
    text-align: center;
    max-width: 60em;
    max-height: 37em;

    @at-root #{&}__label {
      color: $primary;
      font-size: 1.5em;
      margin-bottom: 3rem;
      max-width: 90%;
    }

    input {
      min-width: 60%;
      border: none;
      font-size: 1.2rem;
      font-weight: lighter;
      text-align: center;
      padding: 0.5em;
      border-bottom: 2px solid $primary-light;
      background-color: transparent;
      border-radius: 0;
      height: 3em;

      @include respond-to(phone) {
        width: 90%;
      }
    }

    input:focus-within {
      border-bottom: 2px solid $primary;
    }

    .text-button {
      margin-top: 3rem;
      width: 30%;
      min-width: 20rem;
      font-size: 1em;
      text-transform: uppercase;
      margin-left: 0;
      padding: 1.5rem;
    }
  }
}
