#app-sidebar {
  box-shadow: $box-shadow;

  @include respond-to(phone) {
    display: none;
  }
}

.sidebar {
  @extend .column;
  background-color: white;
  width: 20rem;
  height: 100%;
  overflow-x: hidden;

  @at-root #{&}__logo {
    @extend .row;
    padding: 1.5rem 0;
    width: 100%;
    border-bottom: 1px solid $primary-light;
    justify-content: center;
    height: 6rem;

    img {
      width: 3rem;
    }
  }

  @at-root #{&}__menu {
    flex-grow: 1;
    width: 100%;

    @at-root #{&}__item {
      @extend .row;
      width: 100%;
      color: $grey;
      padding: 1.5rem 0;
      padding-left: 3rem;
      font-size: 1rem;
      font-weight: lighter;
      text-transform: capitalize;
      justify-content: flex-start;
      border-radius: 0;

      .material-icons {
        font-size: 1.4em;
        margin-right: 0.2em;
      }

      &:hover {
        background-color: $primary-light;
      }

      &.active {
        color: white;
        background-color: $primary !important;
        box-shadow: $box-shadow;
      }
    }
  }

  @at-root #{&}__collapse {
    @extend .row;
    font-size: 1rem;
    justify-content: flex-end;
    border-top: 1px solid $primary-light;
    padding: 0 0.6rem;

    .icon-button {
      font-size: 1.2rem;
      color: $grey;
      margin-right: 0;
    }
  }

  @at-root #{&}__user {
    @extend .row;
    width: 100%;
    padding: 1rem;
    border-top: 1px solid $primary-light;
    justify-content: space-between;
    user-select: none;

    @at-root #{&}__info {
      @extend .row;
      color: $black;
      font-size: 1rem;
      flex-grow: 1;
      justify-content: flex-start;

      .user-icon {
        width: 2rem;
        height: 2rem;
        font-size: 1rem;
      }

      .user-name {
        max-width: 70%;

        > * {
          @extend .ellipsis;
        }
      }
    }

    .icon-button {
      font-size: 1.2rem;
      color: $grey;
      margin-right: 0;
    }
  }

  &.compact {
    width: 4rem;

    .sidebar__logo img {
      width: 1.5rem;
    }

    .sidebar__menu__item {
      padding: 1.5rem 0;
      justify-content: center;

      .material-icons {
        margin-right: 0;
      }

      span {
        display: none;
      }
    }

    .sidebar__user,
    .sidebar__collapse {
      justify-content: center;
    }

    .sidebar__user__info {
      display: none;
    }

    #btn-sign-out {
      margin: 0;
    }

    #btn-language {
      display: none;
    }
  }

  @include respond-to(tablet) {
    width: 4rem;

    .sidebar__logo img {
      width: 1.5rem;
    }

    .sidebar__menu__item {
      padding: 1.5rem 0;
      justify-content: center;

      .material-icons {
        margin-right: 0;
      }

      span {
        display: none;
      }
    }

    .sidebar__user {
      justify-content: center;
    }

    .sidebar__collapse,
    .sidebar__user__info {
      display: none;
    }

    #btn-sign-out {
      margin: 0;
    }

    #btn-language {
      display: none;
    }
  }
}
