.dashboard {
  @extend .scrollable;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: 6rem;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  align-items: flex-start;
  height: 100%;
  position: relative;

  @include respond-to(phone) {
    padding: 2rem 1.5rem;
    grid-row-gap: 1.5rem;
  }

  .large {
    grid-area: span 5 / span 4;

    @include respond-to(phone) {
      grid-area: span 4 / span 4;
    }
  }

  .small {
    grid-area: span 4 / span 1;

    @include respond-to(phone) {
      grid-area: span 4 / span 4;
    }
  }

  .empty-placeholder {
    grid-area: span 8 / span 4;
  }

  @at-root #{&}__chart {
    overflow: hidden;
    min-width: 0;
    grid-area: span 4 / span 2;
    width: 100%;
    height: 100%;
    background-color: $white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem 2rem 2rem;
    margin-bottom: 2rem;

    @include respond-to(phone) {
      grid-area: span 4 / span 4;
      padding: 1rem;
    }
  }

  @at-root #{&}__card {
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 2rem;
    box-shadow: $box-shadow;
    border-radius: 2px;
    flex-shrink: 0;
    flex-grow: 1;
    color: $white;
    align-items: center;
    background-image: $gradient-light;
    min-width: 0;
    min-height: 0;

    @include respond-to(phone) {
      grid-area: span 1 / span 2;
    }

    &:first-child {
      background-color: $primary;
    }

    &:nth-child(2) {
      background-color: $purple;
    }

    &:nth-child(3) {
      background-color: $success;
    }

    &:nth-child(4) {
      background-color: $warn;
    }

    @at-root #{&}__icon {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      width: 3rem;

      img {
        height: 3rem;
        filter: brightness(0.7);
      }
    }

    @at-root #{&}__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-grow: 1;
      min-width: 0;

      > * {
        @extend .ellipsis;
      }

      label {
        font-size: 1.8rem;

        span {
          margin: 0;
        }
      }

      span {
        margin-top: 0.2rem;
        padding-left: 2px;
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
}
